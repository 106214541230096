<template>
	<div class="navbar-container d-flex content align-items-center">
		<!-- Nav Menu Toggler -->
		<ul class="nav navbar-nav d-xl-none">
			<li class="nav-item">
				<b-link
					class="nav-link"
					@click="toggleVerticalMenuActive"
				>
					<feather-icon
						icon="MenuIcon"
						size="21"
					/>
				</b-link>
			</li>
		</ul>

		<!-- Left Col -->
		<div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
			<dark-Toggler class="d-none d-lg-block" />
		</div>
		
		<b-navbar-nav class="nav align-items-center ml-auto">
			<locale></locale>
			<li class="nav-item">
				<a
					href="javascript:void(0)"
					class="nav-link nav-link-search"
					@click="showSearchBar = true"
				>
					<feather-icon
						badge="6"
						badge-classes="bg-danger"
						class="text-body"
						icon="BellIcon"
						size="21"
					/>
				</a>
			</li>
			<b-nav-item-dropdown
				right
				toggle-class="d-flex align-items-center dropdown-user-link"
				class="dropdown-user"
			>
				<template #button-content>
					<div class="d-sm-flex d-none user-nav">
						<p class="user-name font-weight-bolder mb-0">
							{{userInfo.nickname}}
						</p>
						<!-- <span class="user-status">Admin</span> -->
					</div>
					<b-avatar
						size="40"
						variant="light-primary"
						:src="userInfo.avatar || $config.defaultAvatar"
						class="badge-minimal"
						badge-variant="success"
					/>
				</template>


				<!-- <b-dropdown-item link-class="d-flex align-items-center">
					<feather-icon
						size="16"
						icon="MessageSquareIcon"
						class="mr-50"
					/>
					<span>Chat</span>
				</b-dropdown-item>

				<b-dropdown-divider /> -->

				<b-dropdown-item link-class="d-flex align-items-center" @click="logoutAction">
					<feather-icon
						size="16"
						icon="LogOutIcon"
						class="mr-50"
					/>
					<span>{{$t('Logout')}}</span>
				</b-dropdown-item>
			</b-nav-item-dropdown>
		</b-navbar-nav>
	</div>
</template>

<script>
import {
	BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import locale from './Locale.vue'
import {mapState} from 'vuex'

export default {
	components: {
		BLink,
		BNavbarNav,
		BNavItemDropdown,
		BDropdownItem,
		BDropdownDivider,
		BAvatar,

		// Navbar Components
		DarkToggler,
		locale
	},
	props: {
		toggleVerticalMenuActive: {
			type: Function,
			default: () => {},
		},
	},
	computed: {
		...mapState({
			userInfo: state => state.app.userInfo||{}
		})
	},
}
</script>
