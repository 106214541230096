export default [
  {
    title: 'Dashboard',
    route: 'admin',
    icon: 'HomeIcon',
  },
  {
    title: 'My Info',
    route: 'myInfo',
    icon: 'InfoIcon',
  },
  {
    title: 'Device',
    route: 'device',
    icon: 'HardDriveIcon',
  },
  {
    title: 'Materials',
    route: 'materials',
    icon: 'ImageIcon',
  },
  {
    title: 'Program',
    route: 'program',
    icon: 'AirplayIcon',
  },
  {
    title: 'Schedule',
    route: 'schedule',
    icon: 'CalendarIcon',
  },
  {
    title: 'Member',
    route: 'member',
    icon: 'UserIcon',
  },
  // {
  //   title: 'Back To Home',
  //   route: 'home',
  //   icon: 'HomeIcon',
  // },
]
