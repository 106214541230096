<template>
	<layout-vertical>
		<router-view />
		<template #navbar="{ toggleVerticalMenuActive }">
			<navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
		</template>
		<template #breadcrumb>
			<Breadcrumb></Breadcrumb>
		</template>
		<template #footer>
			<copyright />
			<!-- <p class="clearfix mb-0">
				<span class="float-md-left d-block d-md-inline-block mt-25">
					COPYRIGHT  © {{ new Date().getFullYear() }}
					{{$t('JiaJing Tech')}}
					<span class="d-none d-sm-inline-block">{{$t('All rights Reserved')}}</span>
					{{beiAnNo}}
				</span>
			</p> -->
		</template>

		<app-customizer
			v-if="showCustomizer"
			slot="customizer"
		/>
	</layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
import Navbar from '../components/Navbar.vue'
import Breadcrumb from '@/layouts/components/Breadcrumb.vue'
import copyright from '@/components/public/Copyright.vue'

export default {
	components: {
		AppCustomizer,
		LayoutVertical,
		Navbar,
		Breadcrumb,
		copyright
	},
	data() {
		return {
			showCustomizer: $themeConfig.layout.customizer
		}
	}
}
</script>
